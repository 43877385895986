<template>
  <main>
    <page-header-compact>
      Registro de Resultados laboratorio
    </page-header-compact>

    <div class="container-fluid">
      <div class="card mt-4">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-12">
              <div class="row">
                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                  <label class="Font-weight-bolder">No. Orden de trabajo </label>
                  <orden-trabajo-field ref="ordenTrabajoField" v-model="ordentrabajo"></orden-trabajo-field>
                </div>
                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                  <label class="Font-weight-bolder">Numero de muestra</label>
                  <input v-model="toma_muestra" class="form-control form-control-sm" type="text" readonly/>
                </div>
                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                  <label class="font-weight-bolder">Fecha </label>
                  <input v-model="fecha" class="form-control form-control-sm" type="date"/>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
              <div class="text-center card-waves mt-2 card-header-actions">
                <div class="card-body ">
                  <div v-if="Object.keys(usuario).length">
                    <div class="form-group">
                      <strong>{{ usuario.nombre_1 }} {{ usuario.nombre_2 }} {{
                          usuario.apellido_1
                        }}
                        {{ usuario.apellido_2 }}</strong>
                      <h6 class="text-body mt-2">FECHA DE NACIMIENTO: {{
                          usuario.fecha_nacimiento
                        }}
                      </h6>
                      <h6 class="text-body">EDAD : <span class="badge badge-secondary">{{
                          usuario.years
                        }}</span></h6>
                    </div>
                  </div>
                  <div v-else class="align-center ">
                    <img class="mb-0" src="@/assets/img-generales/error.png" width="96">
                    Sin resultados...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!areas.length" class="alert alert-info">Sin resultados...</div>
      <div v-show="areas.length" class="card mt-2">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li v-for="(area, i) in areas" :key="area.id" class="nav-item">
              <a :id="`nav_${area.id}`" :class="{ active: i === 0 }" :href="`#tab_${i}`" aria-controls="nav-home"
                 aria-selected="true" class="nav-link " data-toggle="tab" role="tab">{{
                  area.nombre
                }}</a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div id="nav-tabContent" class="tab-content">
            <div v-for="(area, i) in areas" :id="`tab_${i}`" :key="`tab_${area.id}`"
                 :class="{ 'show active': i === 0 }" aria-labelledby="nav-home-tab" class="tab-pane fade pt-2"
                 role="tabpanel">
              <div class="row card-header bg-gray text-black">
                <div class="col-lg-3">
                  <span class="font-weight-bolder">Determinación</span>
                </div>
                <div class="col-lg-3">
                  <span class="font-weight-bolder">Resultado</span>
                </div>
                <div class="col-lg-3">
                  <span class="font-weight-bolder">Observaciones</span>
                </div>
                <div class="col-lg-3">
                  <span class="font-weight-bolder">Valores de Ref.</span>
                </div>
              </div>
              <div class="list-group list-group-flush small">
                <div v-for="examen in area.examenes" :key="examen.id"
                     class="list-group-item">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-weight-bolder">{{ examen.nombre }}</label>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <texto v-if="examen.tipo === 'texto'" v-model="examen.resultado"></texto>
                      <numerico v-if="examen.tipo === 'numero'" v-model="examen.resultado"></numerico>
                      <opciones v-if="examen.tipo === 'opcion'" v-model="examen.resultado"
                                :examen="examen">
                      </opciones>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <textarea v-model="examen.observacion" class="form-control" cols="10" rows="2"
                                  type="text"></textarea>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <h6 v-if="examen.valores_referencia!=0" class="font-weight-bolder">
                          [{{ examen.valores_referencia }}]</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="font-weight-bolder">Profesional</label>
                    <v-select-user v-model="area.id_profesional"></v-select-user>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  <div class="mt-4">
          <div class="card-header text-primary">Examenes</div>
          <div class="card-body">
              <div class="row">
                  <div class="col-lg-4"  v-for="item in detalles" :key="item.id">
                      <div class="card">
                          <div class="card-body">
                              <h6 class="text-black"> {{item.nombre}} - Valor de referencia [{{item.examen.valores_referencia}}] {{item.examen.unidad}}</h6>
                              <texto
                                  v-model="item.resultado"
                                  v-if="item.tipo === 'texto'"
                              ></texto>
                              <numerico
                                  v-model="item.resultado"
                                  v-if="item.tipo === 'numero'"
                              ></numerico>
                              <opciones
                                  :examen="item.examen"
                                  v-model="item.resultado"
                                  v-if="item.examen.tipo === 'opcion'"
                              ></opciones>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->
      <div class="row mt-2">
        <div class="col-12">
          <button class="btn btn-success" @click="save()">
            <i class="fas fa-save fa-fw text-white-50"></i>
            Guardar
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import OrdenTrabajoField from "../../../../components/common/OrdenTrabajoField";
import texto from "./tipos/texto";
import numerico from "./tipos/numerico";
import opciones from "./tipos/opciones";
import Swal from "sweetalert2";
import resultadosLaboratorioService from "../../../../services/resultadosLaboratorioService";
//import asignacionAreasService from "../../../../services/asignacionAreasService";
import {isEmpty} from "lodash";
import VSelectUser from "@/components/common/vSelectUser.vue";

export default {
  components: {
    VSelectUser,
    PageHeaderCompact, OrdenTrabajoField, texto, numerico, opciones
  },
  data() {
    return {
      ordentrabajo: {},
      usuario: {},
      fecha: '',
      detalles: [],
      tieneRespuestas: false,
      areas: [],
      toma_muestra: '',
    }
  },
  watch: {
    ordentrabajo: {
      handler: async function () {
        this.areas = [];
        if (!isEmpty(this.ordentrabajo)) {

          this.usuario = this.ordentrabajo.usuario;
          this.toma_muestra = this.ordentrabajo.toma_muestra;

          const respuestas = await resultadosLaboratorioService.showByOrdenTrabajo(this.ordentrabajo.id);

          if (!isEmpty(respuestas.data)) this.tieneRespuestas = true;

          let examenes = [];

          const testWithSubtest = this.ordentrabajo.detalles.filter(x => x.examen.subexamen.length);
          const testWithoutSubtest = this.ordentrabajo.detalles.filter(x => x.examen.subexamen.length === 0);

          testWithSubtest.forEach(test => {
            examenes.push(...test.examen.subexamen.map(subtest => {

              let respuesta = '';
              let observacion = '';

              if (!isEmpty(respuestas.data)) {

                const tieneRespuesta = respuestas.data.detalles.find(x => parseInt(x.id_examen) === parseInt(subtest.id_examen));

                if (tieneRespuesta !== undefined) {
                  respuesta = tieneRespuesta.resultado;
                  observacion = tieneRespuesta.observacion;
                }

              }

              return {
                id: subtest.id,
                nombre: subtest.nombre,
                observacion: observacion,
                tipo: subtest.tipo,
                opciones: subtest.opciones,
                resultado: respuesta,
              }
            }));
          });

          examenes.push(...testWithoutSubtest.map(test => {

            let respuesta = '';
            let observacion = '';

            if (!isEmpty(respuestas.data)) {

              const tieneRespuesta = respuestas.data.detalles.find(x => parseInt(x.id_examen) === parseInt(test.id_examen));

              if (tieneRespuesta !== undefined) {
                respuesta = tieneRespuesta.resultado;
                observacion = tieneRespuesta.observacion;
              }
            }
            return {
              id: test.examen.id,
              nombre: test.examen.nombre,
              tipo: test.examen.tipo,
              opciones: test.examen.opciones,
              id_area: test.examen.id_area,
              resultado: respuesta,
              observacion: observacion,
              examen: {
                ...test.examen,
                resultado: respuesta,
                observacion: observacion,
              }

            }
          }));

          /*const { data } = await asignacionAreasService.showByDate(this.ordentrabajo.fecha);

          const asignacion = data.length ? data.pop() : [];

          examenes = examenes.map(x => {
              const id_user = asignacion.detalles.find(y => y.id_area_lab === x.id_area)?.id_profesional || 0;
              return {
                  ...x,
                  id_profesional: nu
              }
          });*/

          let areasConExamenes = {};

          // Iterar sobre los detalles de la orden de trabajo
          examenes.forEach(detalle => {
            let examen = detalle.examen;
            let area = examen.area;

            // Si el área ya existe en el objeto, agregar el examen
            if (areasConExamenes[area.id]) {
              areasConExamenes[area.id].examenes.push(examen);
            } else {
              // Si no existe, crear una nueva entrada para el área
              areasConExamenes[area.id] = {
                id: area.id,
                nombre: area.nombre,
                id_profesional: null,
                examenes: [examen]
              };
            }
          });
          this.areas = Object.values(areasConExamenes);
          this.fecha = this.ordentrabajo.fecha;

          // asignar profesional a cada area segun los resultados
          if (!isEmpty(respuestas.data)) {
            this.areas.forEach(area => {
              // buscar resultados de examenes de esta area
              const examenesArea = area.examenes.map(x => x.id);
              const resultadosArea = respuestas.data.detalles.filter(x => examenesArea.includes(x.id_examen));
              // buscar profesional asignado a los resultados
              area.id_profesional = resultadosArea.length ? resultadosArea[0].id_profesional : null;
            });
          }
        } else {
          this.usuario = {};
          this.detalles = [];
        }
      },
      deep: true
    },
  },
  methods: {
    async save() {
      try {
        let resultados = [];

        // buscar areas que tienen resultados

        const areasConExamen = this.areas.filter(x => x.examenes.some(e => e.resultado !== ''));

        // validar que todas las areas tengan un profesional asignado
        const areasSinProfesional = areasConExamen.filter(x => x.id_profesional === null);

        if (areasSinProfesional.length) {
          // concat all areas without professional
          const areasNames = areasSinProfesional.map(x => x.nombre).join(', ');
          await Swal.fire("Ups!", `Debe asignar un profesional a los resultados de area `+areasNames, "error");
          return;
        }

        this.areas.forEach(x => {

          x.examenes.forEach(e => {

            let examenes_n = {
              nombre: e.nombre,
              id_examen: e.id,
              resultado: e.resultado,
              id_profesional: x.id_profesional,
              valores_referencia: e.valores_referencia,
              observacion: e.observacion,
            }

            let resultados_examenes = [
              examenes_n,
              //...subexamenes
            ]

            resultados.push(...resultados_examenes);
          })
        });

        let resultados_laboratorios = {
          id_orden_trabajo: this.ordentrabajo.id,
          fecha: this.fecha,
          resultados: resultados
        }

        this.LoaderSpinnerShow();

        if (this.tieneRespuestas) {
          await resultadosLaboratorioService.update(resultados_laboratorios);
        } else {
          await resultadosLaboratorioService.store(resultados_laboratorios);
          this.tieneRespuestas = true;
        }

        this.LoaderSpinnerHide();

        Swal.fire("Datos Guardados con exito", "", "success");
        this.limpiarCampos();


      } catch (error) {
        console.log(error);
        this.LoaderSpinnerHide();
        Swal.fire("Upss", "Ocurrio un error al procesar la solicitud", "error");
      }
    },
    limpiarCampos() {
      this.ordentrabajo = {};
      this.usuario = {};
      this.areas = [];
      this.tieneRespuestas = false;
      this.fecha = '';
    }
  },
  created() {
    // get work order id from url
    const workOrderId = this.$route.params?.workOrderId;
    if (workOrderId) {
      // next tick to ensure the component is mounted
      this.$nextTick(() => {
        this.$refs.ordenTrabajoField.setIdOrden(workOrderId);
      });
      // get date from query params if present
      const date = this.$route.query?.orderDate;
      if (date) {
        this.fecha = date
      }
    }
  }

}
</script>
