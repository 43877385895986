<template>
    <main>
        <div class="row">
            <div class="mr-0 pr-0 col-xs-12 col-sm-12 col-md-4 col-lg-12">
                <div class="input-group input-group-sm">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        :readonly="isSearch"
                        v-on:keyup.enter.prevent="getOrdenById()"
                        v-model="id_orden"
                        :class="classObject"
                    />
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                    </div>

                </div>
            </div>
            <div class="ml-0 pl-1 col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <input
                    type="hidden"
                    class="form-control form-control-sm"
                    disabled
                    v-model="orden_trabajo.id"
                />
            </div>
        </div>
    </main>
</template>

<script>
import ordenTrabajoService from "../../services/ordenTrabajoService";
import {isEmpty} from 'lodash';

export default {
    props: ["value", "validator"],
    data() {
        return {
            id_orden: "",
            orden_trabajo: this.value,
            isSearch: false,
            random_modal_id : '',
            component : '',
        };
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.orden_trabajo = newValue;
                if (Object.entries(newValue).length === 0) {
                    this.id_orden = "";
                }
            },
            deep: true,
        },
    },
    methods: {
        async getOrdenById() {
            this.isSearch = true;

            let response = null;

            this.LoaderSpinnerShow();

            response = await ordenTrabajoService.showOrdenById(this.id_orden);

            this.LoaderSpinnerHide();

            this.isSearch = false;

            if (!isEmpty(response.data)) {
                this.orden_trabajo = response.data;
                this.$emit("input", this.orden_trabajo);
            }
            else{
                this.orden_trabajo = {};
                this.$emit('input', this.orden_trabajo);
            }
        },
        setChange(value) {
            this.$emit("update-value", value);
        },
        setIdOrden(value) {
            this.id_orden = value;
            this.getOrdenById();
        },
    },
    computed:{
        classObject: function () {

            let _class = {
                "is-invalid": false,
                "is-valid": false,
            };

            if (this.validator !== undefined && this.validator !== null) {
                _class["is-invalid"] = this.validator.$error;
                _class["is-valid"] = !this.validator.$error && this.validator.$dirty;
            }

            return _class;
            },
    }
};
</script>
<style scoped>
</style>
